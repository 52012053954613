























import { Vue, Component, Prop } from 'vue-property-decorator';
import Nav from '@/components/common/Nav.vue';
import SideNav from '@/components/common/SideNav.vue';

@Component({
  components: {
    Nav,
    SideNav,
  },
})
export default class Page extends Vue {
  @Prop({ default: false })
  hasNoPadding!: boolean;

  @Prop({ default: false })
  hasTransparentBackground!: boolean;

  isSideNavigationActive = false;

  setSideNavReactivity(payload: boolean) {
    this.isSideNavigationActive = payload;
  }
}
