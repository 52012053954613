
























import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DropdownItem } from '@/models/dropdown';
@Component({})
export default class Dropdown extends Vue {
  selectedValue: string | null = null;
  isDropdownShown = false;

  @Prop({ required: true })
  selectedText!: string;

  @Prop({ default: false })
  isDisabled!: boolean;

  // This is for Edit purposes. Ideally for set the dropdownState
  @Prop()
  previousSelectedValue!: string;

  @Prop({ required: true })
  data!: DropdownItem[];

  // Close Dropdown function
  closeDropdown(event: any) {
    if (!this.$el.contains(event.target)) {
      this.isDropdownShown = false;
    }
  }

  created() {
    document.addEventListener('click', this.closeDropdown);
  }

  beforeDestroy() {
    document.removeEventListener(`click`, this.closeDropdown);
  }

  @Watch(`previousSelectedValue`)
  @Watch(`data`)
  updateCurrentValue() {
    if (this.previousSelectedValue.length > 0 && this.data.length > 0) {
      const itemIndex = this.data.findIndex((item) => {
        return item.value === this.previousSelectedValue;
      });
      if (itemIndex > -1) {
        this.selectedValue = this.data[itemIndex].value;
        this.updateDropdownState(this.data[itemIndex]);
      }
    }
  }

  @Emit(`select`)
  updateSelectedItem(selectedItem: DropdownItem) {
    this.isDropdownShown = false;
    this.selectedValue = selectedItem.value;
    return selectedItem;
  }

  @Emit(`updateState`)
  updateDropdownState(selectedItem: DropdownItem) {
    return selectedItem;
  }
}
