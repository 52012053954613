





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { MultiUserFormRequest, UserDetailedItemResponse } from '@/models/users';
@Component({
  components: { Dropdown },
})
export default class UserDetailTemplate extends Vue {
  @Prop()
  previousUserData!: UserDetailedItemResponse;

  // General Information Column
  selectedProfilePicture = ``;
  selectedFirstName = ``;
  selectedLastName = ``;
  selectedEmailAddress = ``;
  selectedIsForceChangePassword = false;
  selectedContactNumber = ``;
  selectedDateOfBirth: string | null | Date = null;
  selectedAddress = '';
  selectedAddress2 = '';
  selectedCity = '';
  selectedState = '';
  selectedPostcode = '';
  selectedDegree = '';
  selectedUniversity = '';
  selectedGenderValue = '';
  selectedRoleValue = '';
  selectedTitleValue = '';
  selectedReferenceCode = '';

  currentSource: MultiUserFormRequest = {
    id: -1,
    title_id: -1,
    title: {
      id: -1,
      name: '',
    },
    firstname: ``,
    lastname: ``,
    email: ``,
    password: ``,
    force_update_password: false,
    mobile: ``,
    birthday: ``,
    role: ``,
    address: ``,
    address2: ``,
    city: ``,
    state: ``,
    postcode: ``,
    degree: ``,
    university: ``,
    gender: ``,
    profile_url: ``,
    level_of_study: ``,
    reference_code: ``,
    onboarding_step: -1,
  };

  get fullNameAndTitle() {
    return `${this.selectedTitleValue} ${this.selectedFirstName} ${this.selectedLastName}`;
  }

  mounted() {
    this.repopulateForm();
  }

  @Watch(`previousUserData`)
  repopulateForm() {
    if (this.previousUserData) {
      this.currentSource = {
        id: this.previousUserData.id,
        title_id: this.previousUserData.title_id,
        title: this.previousUserData.title,
        firstname: this.previousUserData.firstname,
        lastname: this.previousUserData.lastname,
        email: this.previousUserData.email,
        password: this.previousUserData.password,
        force_update_password: this.previousUserData.force_update_password,
        mobile: this.previousUserData.mobile,
        birthday: this.previousUserData.birthday,
        role: this.previousUserData.role,
        address: this.previousUserData.address,
        address2: this.previousUserData.address2,
        city: this.previousUserData.city,
        state: this.previousUserData.state,
        postcode: this.previousUserData.postcode,
        degree: this.previousUserData.degree,
        university: this.previousUserData.university,
        gender: this.previousUserData.gender,
        profile_url: this.previousUserData.profile_url,
        reference_code: this.previousUserData.reference_code,
      };
      this.selectedProfilePicture = this.currentSource.profile_url as string;
      this.selectedFirstName = this.currentSource.firstname;
      this.selectedLastName = this.currentSource.lastname;
      this.selectedEmailAddress = this.currentSource.email;
      this.selectedIsForceChangePassword = false;
      this.selectedContactNumber = this.currentSource.mobile;
      this.selectedDateOfBirth = this.currentSource.birthday;
      this.selectedAddress = this.currentSource.address;
      this.selectedAddress2 = this.currentSource.address2;
      this.selectedCity = this.currentSource.city;
      this.selectedState = this.currentSource.state;
      this.selectedPostcode = this.currentSource.postcode;
      this.selectedDegree = this.currentSource.degree
        ? this.currentSource.degree
        : this.selectedDegree;
      this.selectedUniversity = this.currentSource.university
        ? this.currentSource.university
        : this.selectedUniversity;
      this.selectedGenderValue = this.currentSource.gender
        ? this.currentSource.gender
        : this.selectedGenderValue;
      this.selectedRoleValue = this.currentSource.role;
      this.selectedTitleValue = this.currentSource.title?.name
        ? this.currentSource.title?.name
        : this.selectedTitleValue;
      this.selectedReferenceCode = this.currentSource.reference_code? this.currentSource.reference_code: '';
    }
  }

  get showTeacherFields() {
    return this.selectedRoleValue == 'teacher' ? true : false;
  }
}
