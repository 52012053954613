












import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
@Component({})
export default class AddNewLayout extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ default: `/` })
  backRoute!: string;

  @Prop({ default: false })
  useRouterBackRoute!: boolean;

  @Prop({ default: true })
  isBold!: boolean;

  @Emit(`close`)
  goBack() {
    if (!this.useRouterBackRoute) {
      this.$router.push({
        path: this.backRoute,
      });
    } else {
      this.$router.back();
    }
  }
}
